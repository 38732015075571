<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="1280px"
    @closed="form = null">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-position="right" label-suffix=":">
          <div class="h">
            <el-form-item label="入库库房" v-show="form.formType === 5">
              <div style="width: 180px">{{ form.warehouseName || "请输入调拨单号" }}</div>
            </el-form-item>
            <el-form-item label="入库库房" prop="warehouseId" v-show="form.formType !== 5">
              <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName" auto-select-single-option
                displayField="name" url="api/warehouse" filterable placeholder="请选择库房" style="width: 180px" />
            </el-form-item>
            <el-form-item label="入库时间" prop="formTime" label-width="120px">
              <el-date-picker v-model="form.formTime" :clearable="false" value-format="timestamp" style="width: 130px"
                readonly />
            </el-form-item>
            <el-form-item prop="manager" label="入库人" label-width="120px">
              <el-input v-model="form.manager" :maxlength="25" style="width: 130px" />
            </el-form-item>
            <el-form-item label="下单账户">
              <quick-select v-model="buyerErpId" filterable url="api/distributorErps/list" placeholder="选择下单账户"
                value-field="erpId" display-field="name" style="width: 180px" auto-select-first-option />
            </el-form-item>
          </div>
          <el-form-item prop="dependFormCode" label="收货单号" v-if="form.formType === 1">
            <div class="h c">
              <!-- api/orderSendForm/erp   api/orderSendForm/erp/page-->
              <datagrid-picker reference="选择收货单" :pagable="false" dialog dialog-title="选择收货单"
                :params="{ buyerErpId: buyerErpId, startDate: receivingnoteQuery.queryDefine.realSendTime[0], endDate: receivingnoteQuery.queryDefine.realSendTime[1], }"
                :show-index="false" :popper-width="800" reference-type="info" url="api/orderSendForm/erp"
                :query-define="receivingnoteQuery.queryDefine" @change="handleFormCode">
                <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                  <el-date-picker size="small" v-model="receivingnoteQuery.queryDefine.realSendTime" type="daterange"
                    value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']" style="width: 260px;" :picker-options="pickerOptions"
                    :clearable="false" />
                  <el-select v-model="receivingnoteQuery.queryDefine.inStockTypes" multiple clearable
                    placeholder="是否已入库" style="width: 250px;margin:0 5px 0 5px;">
                    <el-option label="未入库" value="NONE" />
                    <el-option label="部分入库" value="PART" />
                    <el-option label="已入库" value="FINISH" />
                  </el-select>
                  <el-button icon="el-icon-search" @click.stop="scope.query" />
                </div>
                <el-table-column prop="formCode" label="单号" width="140" />
                <el-table-column label="单据日期" width="100" :formatter="
                  (r) => {
                    return r.realSendTime
                      ? new Date(r.realSendTime).format('yyyy/MM/dd')
                      : new Date(r.createAt).format('yyyy/MM/dd');
                  }
                " />
                <el-table-column prop="goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price"
                  v-if="
                    checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])
                  " />
                <el-table-column prop="receiverName" label="收货人" min-width="200" />
                <el-table-column prop="minStockType" label="是否已入库" width="100">
                  <template slot-scope="scope">
                    <span v-if="scope.row.inStockType == 'NONE'">未入库</span>
                    <span v-else-if="scope.row.inStockType == 'PART'">部分入库</span>
                    <span v-else>已入库</span>
                  </template>
                </el-table-column>

                <el-table-column label="收货地址" min-width="240" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.recProvince }}{{ scope.row.recDistrict
                    }}{{ scope.row.recCity }}{{ scope.row.recAddress }}
                  </template>
                </el-table-column>
              </datagrid-picker>

              <!-- <datagrid-picker reference="选择收货单" reference-type="info" url="api/orderSendForm/erp/page"
                :params="{ buyerErpId: buyerErpId }" :popper-width="800" :show-index="false"
                pageLayout="total,prev, pager, next,sizes, jumper" @change="handleFormCode">
                <el-table-column prop="formCode" label="单号" width="140" />
                <el-table-column label="单据日期" width="100" :formatter="
                  (r) => {
                    return r.realSendTime
                      ? new Date(r.realSendTime).format('yyyy/MM/dd')
                      : new Date(r.createAt).format('yyyy/MM/dd');
                  }
                " />
                <el-table-column prop="goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price"
                  v-if="
                    checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])
                  " />
                <el-table-column prop="receiverName" label="收货人" min-width="200" />
                <el-table-column prop="minStockType" label="是否已入库" width="100">
                  <template slot-scope="scope">
                    <span v-if="scope.row.inStockType == 'NONE'">未入库</span>
                    <span v-else-if="scope.row.inStockType == 'PART'">部分入库</span>
                    <span v-else>已入库</span>
                  </template>
                </el-table-column>

                <el-table-column label="收货地址" min-width="240" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.recProvince }}{{ scope.row.recDistrict
                    }}{{ scope.row.recCity }}{{ scope.row.recAddress }}
                  </template>
                </el-table-column>
              </datagrid-picker> -->
              <el-select v-model="form.sourceType" placeholder="单据类型" style="width: 120px"
                @change="loadConsignmentOrder">
                <el-option v-for="(v, i) in   sourceType" :key="i" :label="v" :value="i" />
              </el-select>
              <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading"
                :placeholder="handleTips(form.sourceType)" @change="loadConsignmentOrder" style="width: 300px">
                <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
              </el-input>
              <div class="flex"></div>

              <el-checkbox v-model="hideInbound">隐藏已入库产品</el-checkbox>
            </div>
          </el-form-item>
          <el-form-item prop="items" :label="form.formType === 0 ? '' : '入库明细'">
            <el-table :data="itemList" class="table-outdent">
              <el-table-column label="#" type="index" width="40" />
              <!-- <el-table-column prop="code" label="商品编码" min-width="120" /> -->
              <el-table-column prop="erpCode" label="ERP编码" min-width="120" />
              <el-table-column prop="goodsName" label="名称" min-width="160" />
              <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
              <!-- <el-table-column prop="brandName" label="品牌" width="120" /> -->
              <el-table-column prop="dependFormCode" label="采购单号" width="110" />
              <el-table-column key="_lisr_price" prop="goodsPrice" label="单价" width="100" align="right"
                :formatter="$price" v-if="
                  form.formType === 1 &&
                  checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])
                " />
              <el-table-column key="_list_goodsCount" prop="goodsCount" label="应入数量" width="75" align="center"
                v-if="form.formType !== 0" />
              <el-table-column key="_list_receiveCount" prop="receiveCount" label="已入数量" width="75" align="center"
                v-if="form.formType !== 0" />
              <el-table-column label="入库数量" width="110">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.realCount" :min="0.0" :max="scope.row._receivables" :step="1"
                    :precision="2" size="mini" controls-position="right" style="width: 100%"
                    v-if="form.formType === 0 || form.formType === 1" />
                  <span v-else>{{ scope.row.realCount }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="info" label="备注" width="160">
                <template slot-scope="scope">
                  <el-input type="textarea" v-model="scope.row.info" rows="1" />
                </template>
              </el-table-column>
              <el-table-column key="_list_command" width="40">
                <div class="row-commands" slot-scope="scope">
                  <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="合计">
            <div align="right" v-if="form.formType === 1 && checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
              应入（数量：{{ form.sumInfo.planeIn }}，金额：{{ $price(form.sumInfo.planeInPrice) }}）；已入（数量：{{ form.sumInfo.hadIn
              }}，金额：{{ $price(form.sumInfo.hadInPrice) }}）;实入(数量：{{ form.sumInfo.realIn
}}，金额：{{ $price(form.sumInfo.realInPrice) }})
            </div>
            <div align="right" v-else-if="form.formType === 1">
              应入：{{ form.sumInfo.planeIn }}；已入：{{ form.sumInfo.hadIn }}；实入：{{ form.sumInfo.realIn }}</div>
          </el-form-item>
          <el-form-item prop="info" label="摘要">
            <el-input type="textarea" v-model="form.info" :maxlength="100" :rows="3" resize="none" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" type="primary" @click="doSave" :disabled="submiting">确认入库</el-button>
          <!-- <el-button :loading="submiting" type="danger" @click="doSubmit" :disabled="saving" v-if="form.id">保存并入库</el-button> -->
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="入库单号">{{ form.formCode }}</form-info-item>
              <form-info-item label="入库库房">{{ form.warehouseName }}</form-info-item>
              <form-info-item label="入库时间">{{ new Date(form.formTime).format("yyyy/MM/dd") }}</form-info-item>
            </div>
            <div>
              <form-info-item label="入库方式">{{ storageViewTypes[form.formType] }}</form-info-item>
              <form-info-item label="发货单号">{{ form.dependFormCode }}</form-info-item>
              <form-info-item label="入库人" v-if="form.manager">{{ form.manager }}</form-info-item>
            </div>
          </div>
          <el-table border :data="form.items" highlight-current-row empty-text="该订单没有入库的商品" style="margin: 8px 0">
            <!-- <el-table-column prop="code" label="商品编码" min-width="130" /> -->

            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column prop="goodsName" label="名称" min-width="240" />
            <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
            <!-- <el-table-column prop="brandName" label="品牌" width="120" /> -->
            <el-table-column prop="dependFormCode" label="采购单号" width="120" />

            <el-table-column key="_lisr_price" prop="goodsPrice" label="单价" width="100" align="right"
              :formatter="$price" v-if="
                form.formType === 1 &&
                checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])
              " />
            <!-- <el-table-column prop="seriesName" label="系列" width="120" /> -->
            <!-- <el-table-column key="groodPrice" prop="goodsPrice" label="单价" width="100" align="right" :formatter="$price" v-if="form.formType === 1 && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" /> -->
            <el-table-column prop="goodsCount" label="应入数量" width="100" align="center" v-if="form.formType !== 0" />
            <el-table-column prop="realCount" label="入库数量" width="100" align="right" />
            <el-table-column prop="info" label="备注" width="100" show-overflow-tooltip />
          </el-table>

          <form-info-item label="合计">
            <div align="right" v-if="form.formType === 1 && checkPermission(['PURCHASE_ALL', 'PURCHASE_TOTALMONEY'])">
              应入（数量：{{ form.sumInfo.planeIn }}，金额：{{ $price(form.sumInfo.planeInPrice) }}）;实入(数量：{{ form.sumInfo.realIn
              }}，金额：{{ $price(form.sumInfo.realInPrice) }})
            </div>
            <div align="right" v-else-if="form.formType === 1">应入：{{ form.sumInfo.planeIn }}；实入：{{ form.sumInfo.realIn
            }}
            </div>
          </form-info-item>

          <form-info-item label="摘要">{{ form.info }}</form-info-item>
          <el-divider />
          <div class="h c">
            <form-info-item label="当前状态" class="flex">
              <dot same :type="status[form.status].type">{{ status[form.status].label }}</dot>
            </form-info-item>
            <el-button type="text" @click="dialog = false">取消</el-button>
            <!-- <el-button type="primary" @click="doPrint">打印</el-button> -->
          </div>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import {
  get,
  add,
  addByPurchaseConsignmentOrder,
  edit,
  finish,
} from "@/api/storageManage";
import { mapGetters } from "vuex";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";
import { debuglog } from "util";
import checkPermission from "@/utils/permission";
export default {
  components: { skuSelector },
  data() {
    let that = this;
    return {
      loading: false,
      referenceLoading: false, //参考单据加载（如加载发货通知单、退货单等）
      saving: false,
      buyerErpId: null,
      submiting: false,
      searching: false,
      dialog: false,
      mode: null,
      referenceOrder: null,
      hideInbound: false,
      receivingnoteQuery: {
        queryDefine: {
          realSendTime: [
            new Date().getTime() - 3600 * 1000 * 24 * 90,
            new Date().getTime(),
          ],
          inStockTypes: ['NONE', "PART"]
        },
      },
      storageTypes: {
        1: "采购入库",
        3: "退货入库",
        // 5: "调拨入库",
        0: "其他",
      },
      sourceType: {
        saleout: "销售出库单",
        // consignCode: "发货通知单",
        // orderNo: "采购订单",
      },
      storageViewTypes: {
        1: "采购入库",
        3: "退货入库",
        5: "调拨入库",
        0: "其他",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已入库", type: "success" },
      ],
      form: null,
      rules: {
        formTime: [
          { required: true, message: "请选择入库日期", trigger: "change" },
        ],
        warehouseId: [
          { required: true, message: "请选择入库库房", trigger: "change" },
        ],
        items: [
          { required: true, message: "入库明细不能为空" },
          { type: "array", min: 1, message: "入库明细不能为空" },
        ],
        dependFormCode: [{ required: true, message: "请输入相应的单号" }],
      },
      duration: [],
      pickerOptions: {
        disabledDate(time) {
          // return (time.getTime() >= Date.now() - 24 * 3600000 || time.getTime() <= Date.now() - 90 * 24 * 3600000)
          let currentTime = that.duration[0];
          let threeMonths = 90 * 24 * 3600000;
          if (currentTime) {
            return (time.getTime() > currentTime.getTime() + threeMonths || time.getTime() < currentTime.getTime() - threeMonths)
          }
        },
        onPick({ minDate, maxDate }) {
          if (minDate && !maxDate) {
            that.duration[0] = minDate
          }
          if (maxDate) {
            that.duration[1] = maxDate
          }
        },
        shortcuts: [{
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },]
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑入库单"
            : "新建入库单"
          : "查看入库单";
      }
      return str;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
    itemList() {
      let ds = [];
      if (this.form && this.form.items && this.form.items.length) {
        ds = this.form.items.filter((o) => {
          return !this.hideInbound || o.receiveCount < o.goodsCount;
        });
      }
      return ds;
    },
  },
  methods: {
    checkPermission,
    handleTips(sourceType) {
      if (sourceType === "saleout") {
        return "SM打头单号";
      }
      if (sourceType === "consignCode") {
        return "金可儿以SM开头的单号";
      }
      return "请输入相应的采购单号";
    },
    handleFormCode(v, o, res) {
      if (res) {
        this.form.dependFormCode = res.formCode;
        this.loadConsignmentOrder();
      }
    },
    handleFormTypeChange() {
      this.form.dependFormId = "";
      this.form.dependFormCode = "";
      this.form.items = [];
      if (this.form.formType === 5) {
        this.form.warehouseId = null;
        this.form.warehouseName = "";
      }
    },
    loadConsignmentOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        let convert = (list) => {
          this.form.items = (list || []).map((o) => {
            return {
              id: o.id,
              brandName: o.brandName,
              _receivables: (o.goodsCount || 0) - (o.receiveCount || 0),
              receiveCount: o.receiveCount || 0,
              goodsCount: o.goodsCount || 0,
              realCount: o.goodsCount,
              goodsId: o.goodsId,
              goodsName: o.goodsName,
              goodsPrice: o.goodsPrice || 0,
              seriesName: o.seriesName,
              dependFormCode: o.dependFormCode,
              code: o.code,
              erpCode: o.erpCode,
              specs: o.specName,
              info: o.description,
            };
          });

          this.handleSumCount();
        };

        this.referenceLoading = true;
        request({
          url: "api/orderSendForm/erpCode/" + this.form.dependFormCode,
          method: "get",
          params: {
            sourceType: this.form.sourceType,
            buyerErpId: this.buyerErpId,
          },
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.erpCode = res.formCode;
              this.form.entId = res.entId;
              this.form.info = res.info;
              convert(res.items);
            } else {
              this.$alert("没有找到对应的发货单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },

    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          let form, action;
          if (this.form.formType === 1) {
            action = addByPurchaseConsignmentOrder;
            form = JSON.parse(JSON.stringify(this.form));
            form.warehouseId = this.form.warehouseId;
            form.items = this.form.items.filter(o => o.receiveCount < o.goodsCount).map((o) => {
              return {
                id: o.id,
                goodsId: o.goodsId,
                goodsName: o.goodsName,
                brandName: o.brandName,
                seriesName: o.seriesName,
                specName: o.specs,
                goodsCount: o.goodsCount,
                goodsPrice: o.goodsPrice,
                code: o.code,
                erpCode: o.erpCode,
                currentReceiveCount: o.realCount,
                dependFormCode: o.dependFormCode,
                info: o.info,
              };
            });
          } else {
            form = this.form;
            action = this.form.id ? edit : add;
          }
          if (!action) return;
          action(form)
            .then((res) => {
              if (this.form.formType === 1) {
                this.$parent.init();
                this.dialog = false;
              } else {
                if (res) this.resetForm(res);
                if (!this.mode) this.$parent.init();
                if (typeof callback === "function") callback();
                else
                  this.$notify({
                    title: `保存入库单成功`,
                    type: "success",
                    duration: 2500,
                  });
              }
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm("您确定要进行入库吗？", "入库确认", {
          type: "warning",
          dangerouslyUseHTMLString: true,
        }).then((res) => {
          this.doSave((_) => {
            this.submiting = true;
            finish(this.form.id)
              .then((res) => {
                this.$notify({
                  title: "入库确认成功",
                  type: "success",
                  duration: 2500,
                });
                switch (this.mode) {
                  case "purchase":
                    this.$alert(
                      `该采购单已经入库成功，入库单号为：${this.form.formCode}。<br />在“库房管理”中可查看入库单或库存情况。`,
                      "入库提示",
                      {
                        dangerouslyUseHTMLString: true,
                      }
                    );
                    break;
                  default:
                    this.$parent.init();
                    break;
                }
                this.dialog = false;
              })
              .finally((_) => {
                this.submiting = false;
              });
          });
        });
      }
    },
    doPrint() { },
    resetForm(form, type, referenceOrder) {
      this.mode = null;
      this.referenceOrder = referenceOrder;

      if (this.form == null) this.form = {};

      if (form && form.id) {
        this.loading = true;
        get(form.id)
          .then((res) => {
            this.form = res;
            this.handleSumCount();
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = Object.assign(
          {
            status: 0,
            formTime: new Date().getTime(),
            manager: this.user.userRealName,
            warehouseId: null,
            warehouseName: "",
            formType: 1,
            inoutType: 0,
            info: null,
            sourceType: "saleout",
            dependFormCode: referenceOrder ? referenceOrder.formCode : "",
            items: [],
          },
          form || {}
        );
        if (referenceOrder) {
          switch (type) {
            case 1:
              this.loadConsignmentOrder();
              break;
            case 3:
              this.loadReturnOrder();
              break;
            case 5:
              this.form.warehouseId = referenceOrder.inWarehouseId;
              this.loadAllocationOrder();
              break;
          }
        }
      }

      this.handleSumCount();
      this.dialog = true;
    },

    handleSumCount() {
      this.form.sumInfo = {
        planeIn: 0,
        planeInPrice: 0,
        hadIn: 0,
        hadInPrice: 0,
        realIn: 0,
        realInPrice: 0,
      };

      //计算合计
      if (this.form != null && this.form.items != null) {
        this.form.items.forEach((o) => {
          this.form.sumInfo.planeIn = this.form.sumInfo.planeIn + o.goodsCount;
          this.form.sumInfo.hadIn = this.form.sumInfo.hadIn + o.receiveCount;
          this.form.sumInfo.realIn = this.form.sumInfo.realIn + o.realCount;

          this.form.sumInfo.planeInPrice =
            this.form.sumInfo.planeInPrice + o.goodsCount * o.goodsPrice;
          this.form.sumInfo.hadInPrice =
            this.form.sumInfo.hadInPrice + o.receiveCount * o.goodsPrice;
          this.form.sumInfo.realInPrice =
            this.form.sumInfo.realInPrice + o.realCount * o.goodsPrice;
        });

        this.form.sumInfo.planeIn =
          Math.round(this.form.sumInfo.planeIn * 100) / 100;
        this.form.sumInfo.hadIn =
          Math.round(this.form.sumInfo.hadIn * 100) / 100;
        this.form.sumInfo.realIn =
          Math.round(this.form.sumInfo.realIn * 100) / 100;
      }
    },

    handleGoodsAdd(list) {
      if (list && list.length) {
        let ds = this.form.items || [];
        list.forEach((o) => {
          let item = {
            brandName: o.brandName,
            realCount: 1,
            goodsId: o.id,
            code: o.code,
            erpCode: o.erpCode,
            goodsName: o.name,
            seriesName: o.seriesName,
            specs: o.specs,
          };
          ds.push(item);
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o.id === row.id;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },
  },
};
</script>
